@import "./app/variables";

@font-face {
  font-family: Obviously;
  src: local(Obviously-Semi),
  url(/assets/fonts/Obviously-Semibold.woff2) format("woff2"),
  url(/assets/fonts/Obviously-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

body,
html {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Obviously, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  background: $pale-night;
}